import React, { Component } from "react";
import * as moment from "moment";
import { withRouter } from "react-router-dom";
import Countdown from 'react-countdown';

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Loader from "../loader";

import SearchIcon from "@material-ui/icons/Search";

import Store from "../../stores";
import { colors } from "../../theme";

import {
  ERROR,
  START_LOADING,
  STOP_LOADING,
  CONNECTION_CONNECTED,
  ACCOUNT_CHANGED,
  GET_CURRENT_BLOCK,
  CURRENT_BLOCK_RETURNED,
  GET_WARDEN,
  WARDEN_RETURNED,
  GET_JOBS,
  JOBS_RETURNED,
  GET_WARDENS,
  WARDENS_RETURNED,
  ADD_BOND,
  ADD_BOND_RETURNED,
  REMOVE_BOND,
  REMOVE_BOND_RETURNED,
  ACTIVATE_BOND,
  ACTIVATE_BOND_RETURNED,
  WITHDRAW_BOND,
  WITHDRAW_BOND_RETURNED,
  SWAP_APPROVE,
  SWAP_APPROVED,
  SWAP_EXECUTE,
  SWAP_APPROVE_RETURNED,
  SWAP_EXECUTE_RETURNED, TRANSFER_RIGHTS, TRANSFER_RIGHTS_RETURNED
} from '../../constants'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Web3 from "web3";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "900px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "60px",
  },
  grid: {
    flexGrow: 1,
  },
  between: {
    width: "40px",
  },
  intro: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "900px",
    marginBottom: "20px",
    background: colors.darkBlue,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      marginBottom: "10px",
    },
  },
  topButton: {
    width: "160px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
    },
  },
  disclaimer: {
    padding: "12px",
    border: "1px solid rgb(174, 174, 174)",
    borderRadius: "10px",
    marginBottom: "24px",
    background: colors.darkBlue,
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
    },
  },
  wardenLayout: {
    display: "flex",
    maxWidth: "1200px",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  profileContainer: {
    display: "flex",
    width: "300px",
    border: "1px solid " + colors.skyBlue,
    borderRadius: "10px",
    padding: "24px",
    marginRight: "20px",
    flexDirection: "column",
    background: colors.darkBlue,
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
      marginRight: "0px",
      marginBottom: "25px",
    },
  },
  valueContainer: {
    width: "100%",
    margin: "12px 0px",
    position: "relative",
  },
  valueTitle: {
    color: colors.lightGray,
    marginBottom: "6px",
  },
  valueValue: {},
  valueAction: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  valueActionBonds: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  valueActionBondsAction: {
    marginTop: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "100%",
  },
  jobsContainer: {
    display: "flex",
    flex: 1,
    border: '1px solid '+colors.skyBlue,
    borderRadius: "10px",
    padding: "24px",
    marginLeft: "20px",
    flexDirection: "column",
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      maxWidth: "90vw",
      marginBottom: "20px",
    },
    background: colors.darkBlue,
  },
  cardActions: {
    // zIndex: 2,
    // display: 'flex',
    alignItems: "flex-start",
    justifyContent: "flex-end",
    // flexWrap: 'wrap',
    // padding: 0,
  },
  title: {
    width: "100%",
    borderBottom: "1px solid " + colors.skyBlue,
    paddingBottom: "12px",
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "40px",
  },
  inputContainer: {
    flex: 1,
    display: "flex",
    position: "relative",
  },
  balance: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    cursor: "pointer",
    position: "absolute",
    zIndex: 1,
    right: "8px",
    top: "2px",
    letterSpacing: "0.1rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textField: {
    flex: 1,
    width: "100%",
  },
  valueActionButtons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px",
  },
  searchInputAdornment: {
    cursor: "pointer",
  },
  actionInput: {
     background: colors.darkBlue
  },
  jobContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "10px 0px",
    // marginBottom: "8px"
    // "&:hover": {
    background: colors.darkBlue
    // },
  },
  gray: {
    color: colors.darkBlue,
  },
  totalCredits: {
    textAlign: "right",
  },
});

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

class Warden extends Component {
  constructor(props) {
    super();

    const account = store.getStore("account");
    const wardenAsset = store.getStore("wardenAsset");
    const jobs = store.getStore("jobs").filter((job) => {
      return job.credits > 0;
    });
    const now = store.getStore("currentBlock");

    this.state = {
      loading: true,
      account: account,
      wardenAsset: wardenAsset,
      jobs: jobs,
      bondAmount: "",
      bondAmountError: false,
      removeBondAmount: "",
      removeBondAmountError: false,
      currentBlock: now,
      approved:false,
      swapped:false,
      transferTo: "",
    };

    emitter.emit(START_LOADING, GET_WARDEN);
    emitter.emit(START_LOADING, GET_JOBS);
    emitter.emit(START_LOADING, GET_WARDENS);
    emitter.emit(START_LOADING, GET_CURRENT_BLOCK);
    dispatcher.dispatch({ type: GET_WARDEN, content: {} });
    dispatcher.dispatch({ type: GET_JOBS, content: {} });
    dispatcher.dispatch({ type: GET_WARDENS, content: {} });
    dispatcher.dispatch({ type: GET_CURRENT_BLOCK, content: {} });
  }

  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(WARDEN_RETURNED, this.wardenProfileReturned);
    emitter.on(JOBS_RETURNED, this.jobsReturned);
    emitter.on(WARDENS_RETURNED, this.wardensReturned);
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(ACCOUNT_CHANGED, this.connectionConnected);
    emitter.on(ADD_BOND_RETURNED, this.addBondReturned);
    emitter.on(REMOVE_BOND_RETURNED, this.removeBondReturned);
    emitter.on(ACTIVATE_BOND_RETURNED, this.activateBondReturned);
    emitter.on(WITHDRAW_BOND_RETURNED, this.withdrawBondReturned);
    emitter.on(SWAP_APPROVE_RETURNED, this.swapApproveReturned);
    emitter.on(SWAP_EXECUTE_RETURNED, this.swapExecuteReturned);
    emitter.on(CURRENT_BLOCK_RETURNED, this.currentBlockReturned);
    emitter.on(TRANSFER_RIGHTS_RETURNED, this.transferRightsReturned)
  }

  componentWillUnmount() {
    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(WARDEN_RETURNED, this.wardenProfileReturned);
    emitter.removeListener(JOBS_RETURNED, this.jobsReturned);
    emitter.removeListener(WARDENS_RETURNED, this.wardensReturned);
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(ACCOUNT_CHANGED, this.connectionConnected);
    emitter.removeListener(ADD_BOND_RETURNED, this.addBondReturned);
    emitter.removeListener(REMOVE_BOND_RETURNED, this.removeBondReturned);
    emitter.removeListener(ACTIVATE_BOND_RETURNED, this.activateBondReturned);
    emitter.removeListener(WITHDRAW_BOND_RETURNED, this.withdrawBondReturned);
    emitter.removeListener(CURRENT_BLOCK_RETURNED, this.currentBlockReturned);
    emitter.removeListener(TRANSFER_RIGHTS_RETURNED, this.transferRightsReturned)
  }

  connectionConnected = () => {
    emitter.emit(START_LOADING, GET_WARDEN);
    emitter.emit(START_LOADING, GET_JOBS);
    emitter.emit(START_LOADING, GET_WARDENS);
    emitter.emit(START_LOADING, GET_CURRENT_BLOCK);
    emitter.emit(START_LOADING, SWAP_APPROVED);
    //Finally get the approval status
    dispatcher.dispatch({ type: SWAP_APPROVED, content: {} });
    dispatcher.dispatch({ type: GET_WARDEN, content: {} });
    dispatcher.dispatch({ type: GET_JOBS, content: {} });
    dispatcher.dispatch({ type: GET_WARDENS, content: {} });
    dispatcher.dispatch({ type: GET_CURRENT_BLOCK, content: {} });
  };

  errorReturned = (source) => {
    emitter.emit(STOP_LOADING, source);
    this.setState({ loading: false });
  };

  wardenProfileReturned = () => {
    emitter.emit(STOP_LOADING, GET_WARDENS);

    console.log(store.getStore("wardenAsset"));

    this.setState({
      wardenAsset: store.getStore("wardenAsset"),
      loading: false,
    });
  };

  jobsReturned = () => {
    emitter.emit(STOP_LOADING, GET_JOBS);
    this.setState({
      jobs: store.getStore("jobs").filter((job) => {
        return job.credits > 0;
      }),
    });
  };

  wardensReturned = () => {
    emitter.emit(STOP_LOADING, GET_WARDENS);
    this.setState({ wardens: store.getStore("wardens") });
  };

  addBondReturned = () => {
    this.setState({
      loading: false,
      onBond: false,
    });
    emitter.emit(STOP_LOADING, ADD_BOND);
  };

  removeBondReturned = () => {
    this.setState({
      loading: false,
      onBondRemove: false,
    })
    emitter.emit(STOP_LOADING, REMOVE_BOND)
  }

  activateBondReturned = () => {
    this.setState({
      loading: false,
    });
    emitter.emit(STOP_LOADING, ACTIVATE_BOND_RETURNED);
  };

  withdrawBondReturned = () => {
    this.setState({
      loading: false,
    })
    emitter.emit(STOP_LOADING, WITHDRAW_BOND)
  }

  swapApproveReturned = () => {
    this.setState({
      loading: false,
      approved:true
    })
    emitter.emit(STOP_LOADING, SWAP_APPROVE)
  }

  swapExecuteReturned = () => {
    this.setState({
      loading: false,
      swapped:true
    })
    emitter.emit(STOP_LOADING, SWAP_EXECUTE)
  }

  currentBlockReturned = () => {
    emitter.emit(STOP_LOADING, GET_CURRENT_BLOCK);
    this.setState({ currentBlock: store.getStore("currentBlock") });
  };

  transferRightsReturned = () => {
    emitter.emit(STOP_LOADING, TRANSFER_RIGHTS);
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      wardenAsset,
      wardens,
      onBond,
      onBondRemove,
      currentBlock,
      searchWarden,
      searchWardenError,
    } = this.state;

    // var delegates = 'Self';
    // if (wardenAsset.delegates && wardenAsset.delegates !== '0x0000000000000000000000000000000000000000') {
    //   delegates = wardenAsset.delegates.substring(0,6)+'...'+wardenAsset.delegates.substring(wardenAsset.delegates.length-4,wardenAsset.delegates.length)
    // }

    return (
      <div className={ classes.root }>
        <div className={ classes.wardenLayout }>
          <Card className={ classes.profileContainer }>
            <Typography variant='h3' className={ classes.title }>Profile</Typography>
            <div className={ classes.valueContainer }>
              <Typography variant='h4' className={ classes.valueTitle }>Balance</Typography>
              <Typography variant='h3' className={ classes.valueValue }> { wardenAsset.balance ? wardenAsset.balance.toFixed(2) : '0.00' } { wardenAsset.symbol } </Typography>
            </div>
            <div className={classes.valueContainer}>
              <Typography variant="h4" className={classes.valueTitle}>
                Bonds
              </Typography>
              {!onBond && !onBondRemove && this.renderBond()}
              {onBond && this.renderBondAdd()}
              {onBondRemove && this.renderBondRemove()}
            </div>
            { this.renderPendingBonds() }
            { this.renderActivateBonds(loading) }
            { this.renderPendingUnbonds() }
            { this.renderWithdrawBonds() }
            { this.renderWorkCompleted() }
            { this.renderFirstSeen() }
            { this.renderTransferButton() }
            { this.renderSearch() }
          </Card>
          <Card className={classes.jobsContainer}>
            <CardHeader
              title={
                // <div className={classes.title}>
                <Typography variant="h3">Jobs</Typography>
              }
              action={
                <Tooltip title="Add Job" aria-label="add">
                  <Fab
                    color="primary"
                    size="medium"
                    // variant="extended"
                    color="primary"
                    onClick={this.onAddJob}
                    aria-label="add"
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              }
            ></CardHeader>
            <CardContent>
              <List component="nav" aria-label="main mailbox folders">
                {this.renderJobs()}
              </List>
            </CardContent>
            <CardActions className={classes.cardActions}>
              {/* <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.onAddJob}
              >
                Add
              </Button> */}
            </CardActions>
          </Card>
        </div>
      </div>
    );
  }

  renderSearch = () => {
    const { classes } = this.props
    const { loading, searchWarden, searchWardenError } = this.state

    return (
      <div className={ classes.valueContainer }>
        <TextField
          fullWidth
          disabled={ loading }
          className={ classes.actionInput }
          id={ 'searchWarden' }
          value={ searchWarden }
          error={ searchWardenError }
          onChange={ this.onSearchChange }
          label="Search ward3n"
          onKeyDown= { this.onSearchKeyDown }
          InputProps={{
            endAdornment: <InputAdornment position="end" className={ classes.searchInputAdornment } onClick={ this.onSearch }><SearchIcon /></InputAdornment>,
          }}
        />
      </div>
    )
  }

  /*renderSwap = () => {
    const { classes } = this.props
    const { approved,wardenAsset,loading} = this.state
    let timeRemaining = (moment(1605550155*1000) -  moment().valueOf() )/ 1000;
    if(wardenAsset.balanceWD3NV2 >0 && timeRemaining <=0){
      return(
        <div className={ classes.valueContainer }>
          <Typography variant="h4" className={classes.valueTitle}>
                Swap to WardenV3
            </Typography>
          <Button onClick={this.onSwapApprove} disabled={loading} variant="contained" color="primary" size="medium">Approve</Button>
          <Button onClick={this.onSwapExecute} disabled={!approved || loading} variant="contained" color="primary" size="medium">Swap</Button>
        </div>
      )
    }
    else if (wardenAsset.balanceWD3NV2 >0 && timeRemaining >0){
      return (
        <div>
        <Typography variant="h4" className={classes.valueTitle}>
          Swap to WardenV3
        </Typography>
        <Typography variant="h4" className={classes.valueTitle}>
          Time Remaining <Countdown date={1605550155*1000} />
        </Typography>
        </div>
      )
    }

  }*/

   removeDuplicates = (originalArray, prop) =>{
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }

  renderJobs = () => {
    const { classes } = this.props;
    var { jobs, wardenAsset } = this.state;
    jobs = this.removeDuplicates(jobs,"address");
    if (jobs.length === 0) {
      return (
        <div>
          <Typography variant="h4">There are no jobs</Typography>
        </div>
      );
    }

    return jobs.map((job) => {
      var address = null;
      if (job.address) {
        address =
          job.address.substring(0, 6) +
          "..." +
          job.address.substring(job.address.length - 4, job.address.length);
      }

      return (
        <ListItem
          alignItems="flex-start"
          button
          onClick={() => {
            this.navJob(job.address);
          }}
        >
          <ListItemText
            className={classes.jobContainer}
            key={job.address}
            primary={
              <React.Fragment>
                <div>
                  <Typography variant="h4">{job._name}</Typography>
                  <Typography
                    variant="h4"
                    className={classes.textColorSecondary}
                  >
                    {address}
                  </Typography>
                </div>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <div className={classes.totalCredits}>
                  <Typography
                    variant="h4"
                    className={classes.textColorSecondary}
                  >
                    Total Credits
                  </Typography>
                  <Typography variant="h4">
                    {job.credits ? job.credits.toFixed(2) : "0.00"}{" "}
                    {wardenAsset ? wardenAsset.symbol : ""}
                  </Typography>
                </div>
              </React.Fragment>
            }
          />
        </ListItem>
      );
    });
  };

  renderFirstSeen = () => {
    const { classes } = this.props
    const { wardenAsset } = this.state

    if(wardenAsset.isActive) {
      return (
        <React.Fragment>
          <div className={ classes.valueContainer }>
            <Typography variant='h4' className={ classes.valueTitle }>First Seen</Typography>
            <Typography variant='h3' className={ classes.valueValue }> { moment(wardenAsset.firstSeen*1000).format("YYYY/MM/DD kk:mm") }</Typography>
          </div>
          <div className={classes.valueContainer}>
            <Typography variant="h4" className={classes.valueTitle}>
              Last Job
            </Typography>
            <Typography variant="h3" className={classes.valueValue}>
              {" "}
              {moment(wardenAsset.lastJob * 1000).format("YYYY/MM/DD kk:mm")}
            </Typography>
          </div>
        </React.Fragment>
      )
    } else {
      return null
    }
  };

  renderTransferButton = () => {
    const { classes } = this.props
    const { wardenAsset,loading } = this.state
    const {transfertDialogOpen } = this.state
    const { transferTo } = this.state
    const openTransferDialog = () => {
      this.setState({
        transfertDialogOpen: true,
      });
    }

    const closeDialog = () => {
      this.setState({
        transfertDialogOpen: false,
        transferTo: "",
      });
    }

    const setTransferTo = (value) => {
      this.setState({
        transferTo: value,
      });
    }
    if(wardenAsset.isActive) {
      return (
          <React.Fragment>
            <div className={ classes.valueContainer }>
              <Typography variant='h4' className={ classes.valueTitle }>Transfer warden rights</Typography>

              <Button color={"primary"} variant={"contained"} onClick={openTransferDialog}>Transfer</Button>

            </div>
            <Dialog onClose={closeDialog} aria-labelledby="transfer-warden-rights-dialog-title" open={!!transfertDialogOpen}>
              <DialogTitle id="transfer-warden-rights-dialog-title">Transfer your warden rights</DialogTitle>
              <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="to"
                    label="To address"
                    disabled={loading}
                    fullWidth
                    value={transferTo}
                    onChange={e => setTransferTo(e.target.value)}
                    error={this.state.transferToError}
                    helperText={this.state.transferToError}
                    style={{width:500}}
                />
                <Box textAlign={"center"} my={2}>
                  <Button color="primary" variant="contained" size="small" disabled={loading} onClick={this.onTransfer}>
                    Transfer
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </React.Fragment>
      )
    } else {
      return null
    }

  }

  renderWorkCompleted = () => {
    const { classes } = this.props
    const { wardenAsset } = this.state

    return (
      <div className={ classes.valueContainer }>
        <Typography variant='h4' className={ classes.valueTitle }>Work Rewards</Typography>
        <Typography variant='h3' className={ classes.valueValue }>{ (wardenAsset.workCompleted ? wardenAsset.workCompleted.toFixed(4) : '0') + ` ${wardenAsset.symbol}` }</Typography>
      </div>
    )

  }

  renderBond = () => {
    const { classes } = this.props;
    const { wardenAsset } = this.state;

    return (
      <div className={classes.valueActionBonds}>
        <Typography variant="h3" className={classes.valueValue}>
          {" "}
          {wardenAsset.bonds ? wardenAsset.bonds.toFixed(2) : "0.00"}{" "}
          {wardenAsset.symbol}{" "}
        </Typography>
        <Grid container className={classes.grid} spacing={4}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid key={"bond"} item>
                <Button size="small" color="primary" onClick={this.onBondAdd}>
                  Bond
                </Button>
              </Grid>
              <Grid key={"unbond"} item>
                <Button size="small" color="primary" onClick={this.onBondRemove}>
                  Unbond
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderPendingBonds = () => {
    const { classes } = this.props;
    const { wardenAsset } = this.state;

    if(wardenAsset.isActive && wardenAsset.pendingBonds === 0) {
      return null
    } else if(parseInt(wardenAsset.bondings) - parseInt(wardenAsset.bondingDelay) > 0) {
      return (
        <div className={ classes.valueContainer }>
          <Typography variant='h4' className={ classes.valueTitle }>Bonds pending</Typography>
          <div className={ classes.valueAction }>
            <Typography variant='h3' className={ classes.valueValue }> { wardenAsset.pendingBonds ? wardenAsset.pendingBonds.toFixed(2) : '0.00' } { wardenAsset.symbol } </Typography>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderActivateBonds = (loading) => {
    const { classes } = this.props
    const {
      wardenAsset,
    } = this.state

    if(wardenAsset.isActive && wardenAsset.pendingBonds === 0) {
      return null
    } else if(parseInt(wardenAsset.bondings) - parseInt(wardenAsset.bondingDelay) > 0 && moment(wardenAsset.bondings*1000).valueOf() < moment().valueOf() && wardenAsset.pendingBonds) {
      return (
        <div className={ classes.valueContainer }>
          <Typography variant='h4' className={ classes.valueTitle }>Activate</Typography>
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={ this.onActivate }
            disabled={loading}
          >
            Activate Bonds
          </Button>
        </div>
      )
    } else if (parseInt(wardenAsset.bondings) - parseInt(wardenAsset.bondingDelay) > 0 && wardenAsset.pendingBonds) {
      return (
        <div className={ classes.valueContainer }>
          <Typography variant='h4' className={ classes.valueTitle }>Activatable at</Typography>
          <div className={ classes.valueAction }>
            <Typography variant='h3' className={ classes.valueValue }> { moment(wardenAsset.bondings*1000).format("YYYY/MM/DD kk:mm") } </Typography>
          </div>
        </div>
      )
    }
  }

  renderPendingUnbonds = () => {
    const { classes } = this.props
    const {
      wardenAsset,
    } = this.state

    if(parseInt(wardenAsset.unbondings) - parseInt(wardenAsset.unbondingDelay) > 0 && wardenAsset.partialUnbonding) {
      return (
        <div className={ classes.valueContainer }>
          <Typography variant='h4' className={ classes.valueTitle }>Unbonds pending</Typography>
          <div className={ classes.valueAction }>
            <Typography variant='h3' className={ classes.valueValue }> { wardenAsset.partialUnbonding ? wardenAsset.partialUnbonding.toFixed(2) : '0.00' } { wardenAsset.symbol } </Typography>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  renderWithdrawBonds = () => {
    const { classes } = this.props
    const {
      wardenAsset,
      withdrawBondAmount,
      withdrawBondAmountError,
      loading
    } = this.state

    if((parseInt(wardenAsset.unbondings) - parseInt(wardenAsset.unbondingDelay)) > 0 && moment(wardenAsset.unbondings*1000).valueOf() < moment().valueOf() && wardenAsset.partialUnbonding) {
      return (
        <div>
          <div className={ classes.valueActionButtons }>
            <Button
              variant='contained'
              size='small'
              color='primary'
              onClick={ this.onWithdraw }
            >
              withdraw
            </Button>
          </div>
        </div>
      )
    } else if ((parseInt(wardenAsset.unbondings) - parseInt(wardenAsset.unbondingDelay) ) >0 && wardenAsset.partialUnbonding) {
      return (
        <div className={ classes.valueContainer }>
          <Typography variant='h4' className={ classes.valueTitle }>Withdrawable at</Typography>
          <div className={ classes.valueAction }>
            <Typography variant='h3' className={ classes.valueValue }> { moment(wardenAsset.unbondings*1000).format("YYYY/MM/DD kk:mm") } </Typography>
          </div>
        </div>
      )
    }
  }

  renderBondAdd = () => {
    const { classes } = this.props;
    const { wardenAsset, bondAmount, bondAmountError, loading } = this.state;

    return (
      <div>
        <div className={classes.inputContainer}>
          <Typography
            variant="h6"
            className={classes.balance}
            onClick={() => {
              this.maxClicked("bond");
            }}
          >
            {wardenAsset.balance.toFixed(4)} {wardenAsset.symbol}
          </Typography>
          <TextField
            fullwidth
            disabled={loading}
            id="bondAmount"
            variant="outlined"
            color="primary"
            className={classes.textField}
            placeholder="Amount to bond"
            value={bondAmount}
            error={bondAmountError}
            onChange={this.onChange}
            InputProps={{
              className: classes.inputField,
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <img
                    src={require("../../assets/tokens/" + wardenAsset.logo)}
                    width="30px"
                    alt=""
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.valueActionButtons}>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={this.onBondAddClose}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={this.onBond}
          >
            bond
          </Button>
        </div>
      </div>
    );
  };

  renderBondRemove = () => {
    const { classes } = this.props;
    const {
      wardenAsset,
      removeBondAmount,
      removeBondAmountError,
      loading,
    } = this.state;

    return (
      <div>
        <div className={ classes.inputContainer }>
          <Typography variant='h6' className={ classes.balance } onClick={ () => { this.maxClicked('bondRemove') } }>{ wardenAsset.bonds.toFixed(2)} { wardenAsset.symbol }</Typography>
          <TextField
            fullwidth
            disabled={loading}
            id="removeBondAmount"
            variant="outlined"
            color="primary"
            className={classes.textField}
            placeholder="Amount to unbond"
            value={removeBondAmount}
            error={removeBondAmountError}
            onChange={this.onChange}
            InputProps={{
              className: classes.inputField,
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <img
                    src={require("../../assets/tokens/" + wardenAsset.logo)}
                    width="30px"
                    alt=""
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.valueActionButtons}>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={this.onBondRemoveClose}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={this.onCallBondRemove}
          >
            unbond
          </Button>
        </div>
      </div>
    );
  };

  maxClicked = (type) => {
    const { wardenAsset } = this.state;

    switch (type) {
      case "bond":
        this.setState({ bondAmount: wardenAsset.balance });
        break;
      case "bondRemove":
        this.setState({ removeBondAmount: wardenAsset.bonds});
        break;
      case 'bondWithdraw':
        this.setState({ withdrawBondAmount: wardenAsset.bonds })
        break;
      case 'swapTokens':
          this.setState({ swapAmount: wardenAsset.balance })
          break;
      default:
    }
  };

  onSearchChange = (event) => {
    let val = [];
    val[event.target.id] = event.target.value;
    this.setState(val);
  };

  onSearchKeyDown = (event) => {
    if (event.which === 13) {
      this.onSearch();
    }
  };

  onSearch = () => {
    this.setState({ searchWardenError: false });
    const { searchWarden } = this.state;

    let error = false;

    if (!searchWarden || searchWarden === "") {
      this.setState({ searchWardenError: "Invalid" });
      error = true;
    }

    if (!error) {
      this.props.history.push("/ward3n/" + searchWarden);
    }
  };

  onChange = (event) => {
    if (event.target.value !== "" && isNaN(event.target.value)) {
      return false;
    }

    const { wardenAsset } = this.state;
    if (
      event.target.id === "bondAmount" &&
      event.target.value > wardenAsset.balance
    ) {
      event.target.value = wardenAsset.balance;
    }
    if (
      event.target.id === "removeBondAmount" &&
      event.target.value > wardenAsset.bonds
    ) {
      event.target.value = wardenAsset.bonds;
    }

    let val = [];
    val[event.target.id] = event.target.value;
    this.setState(val);
  };

  onBond = () => {
    this.setState({ bondAmountError: false });
    const { wardenAsset, bondAmount } = this.state;

    let error = false;

    if (bondAmount > wardenAsset.balance) {
      error = true;
      this.setState({ bondAmountError: "Amount > balance" });
    }

    if (!error) {
      emitter.emit(START_LOADING, ADD_BOND);
      this.setState({ loading: true });
      dispatcher.dispatch({ type: ADD_BOND, content: { amount: bondAmount } });
    }
  };

  onWithdraw = () => {
      emitter.emit(START_LOADING, WITHDRAW_BOND);
      this.setState({ loading: true });
      dispatcher.dispatch({ type: WITHDRAW_BOND});
  };

  onSwapApprove = () => {
    let error = false;

    if (!error) {
      emitter.emit(START_LOADING, SWAP_APPROVE);
      this.setState({ loading: true });
      dispatcher.dispatch({ type: SWAP_APPROVE});
    }
  };

  onSwapExecute = () => {
    let error = false;

    if (!error) {
      emitter.emit(START_LOADING, SWAP_EXECUTE);
      this.setState({ loading: true });
      dispatcher.dispatch({ type: SWAP_EXECUTE});
    }
  };

  onCallBondRemove = () => {
    this.setState({ bondAmountError: false });
    const { wardenAsset, removeBondAmount } = this.state;

    let error = false;

    if(removeBondAmount > wardenAsset.bonds) {
      error = true
      this.setState({ removeBondAmountError: 'Amount > bonded balance' })
    }

    if (!error) {
      emitter.emit(START_LOADING, REMOVE_BOND);
      this.setState({ loading: true });
      dispatcher.dispatch({
        type: REMOVE_BOND,
        content: { amount: removeBondAmount },
      });
    }
  };

  onBondAdd = () => {
    this.setState({ onBond: true });
  };

  onBondAddClose = () => {
    this.setState({ onBond: false });
  };

  onBondRemove = () => {
    this.setState({ onBondRemove: true });
  };

  onBondRemoveClose = () => {
    this.setState({ onBondRemove: false });
  };

  onActivate = () => {
    emitter.emit(START_LOADING, ACTIVATE_BOND);
    this.setState({ loading: true });
    dispatcher.dispatch({ type: ACTIVATE_BOND, content: {} });
  };

  onAddJob = () => {
    this.props.history.push("/ward3n/job");
  };

  onTransfer = () => {
    let error = false;
    if (!Web3.utils.isAddress(this.state.transferTo)){
      error = true;
      this.setState({transferToError: "This is not a valid address"});
    }
    if (!error) {
      emitter.emit(START_LOADING, TRANSFER_RIGHTS);
      this.setState({ loading: true, transferToError: null });
      dispatcher.dispatch({ type: TRANSFER_RIGHTS, content: { to: this.state.transferTo } });
    }
  }

  navJob = (address) => {
    this.props.history.push("/ward3n/job/" + address);
  };
}

export default withRouter(withStyles(styles)(Warden));
