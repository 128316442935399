import config from "../config/config.js";
import async from 'async';
import {
  ACTIVATE_BOND,
  ACTIVATE_BOND_RETURNED,
  ADD_BOND,
  ADD_BOND_RETURNED,
  ADD_CREDITS,
  ADD_CREDITS_RETURNED,
  ADD_JOB,
  ADD_JOB_RETURNED,
  ADD_LIQUIDITY_TO_JOB,
  ADD_LIQUIDITY_TO_JOB_RETURNED,
  APPLY_CREDIT_TO_JOB,
  APPLY_CREDIT_TO_JOB_RETURNED,
  BALANCES_RETURNED,
  CALCULATE_MINT,
  CALCULATE_MINT_RETURNED,
  CURRENT_BLOCK_RETURNED,
  ERROR,
  GAS_PRICES_RETURNED,
  GET_BALANCES,
  GET_CURRENT_BLOCK,
  GET_GAS_PRICES,
  GET_GOVERNANCE_ADDRESS,
  GET_GOVERNANCE_ADDRESS_RETURNED,
  GET_JOB_PROFILE,
  GET_JOBS,
  GET_WARDEN,
  GET_WARDEN_PROFILE,
  GET_WARDENS,
  GET_PROPOSALS,
  GET_PROPOSALS_RETURNED,
  GET_REWARD,
  GET_REWARDS_AVAILABLE,
  JOB_PROFILE_RETURNED,
  JOBS_RETURNED,
  WARDEN_PROFILE_RETURNED,
  WARDEN_RETURNED,
  WARDENS_RETURNED,
  MINT,
  MINT_RETURNED,
  PROPOSE,
  PROPOSE_RETURNED,
  REMOVE_BOND,
  REMOVE_BOND_RETURNED,
  REMOVE_LIQUIDITY_FROM_JOB,
  REMOVE_LIQUIDITY_FROM_JOB_RETURNED,
  REWARD_RETURNED,
  REWARDS_AVAILABLE_RETURNED,
  SIGN_ACTION,
  SIGN_ACTION_RETURNED,
  SLASH,
  SLASH_RETURNED,
  SNACKBAR_ERROR,
  STAKE,
  STAKE_RETURNED,
  SWAP_APPROVE,
  SWAP_APPROVE_RETURNED,
  SWAP_APPROVED,
  SWAP_EXECUTE,
  SWAP_EXECUTE_RETURNED,
  TRANSFER_RIGHTS,
  TRANSFER_RIGHTS_RETURNED,
  TX_CONFIRMED,
  TX_RECEIPT,
  TX_SUBMITTED,
  UNBOND_LIQUIDITY_FROM_JOB,
  UNBOND_LIQUIDITY_FROM_JOB_RETURNED,
  VOTE_AGAINST,
  VOTE_AGAINST_RETURNED,
  VOTE_FOR,
  VOTE_FOR_RETURNED,
  WITHDRAW_BOND,
  WITHDRAW_BOND_RETURNED,
} from '../constants';
import Web3 from 'web3';
import {injected} from "./connectors";
import {ERC20ABI} from "./abi/erc20ABI";
import {GovernanceABI} from './abi/governanceABI';
import {LiquidityABI} from './abi/liquidityABI';
import {RewardsABI} from './abi/rewardsABI';
import {PoolABI} from './abi/poolABI';
import {WardenABI} from './abi/wardenABI';
import {SwaperAbi} from './abi/swaperABI';

import {JobRegistryABI} from './abi/jobRegistryABI';
import {OwnableABI} from "./abi/ownableABI";

const rp = require('request-promise');

const Dispatcher = require('flux').Dispatcher;
const Emitter = require('events').EventEmitter;

const dispatcher = new Dispatcher();
const emitter = new Emitter();

class Store {
  constructor() {

    this.store = {
      account: {},
      transactions: [],
      gasPrices: {
        "slow":90,
        "standard":90,
        "fast":100,
        "instant":130
      },
      gasSpeed: 'fast',
      currentBlock: 0,
      connectorsByName: [
        {
          name: 'Metamask',
          icon: 'icn-metamask.svg',
          connector: injected
        }
      ],
      web3context: null,
      baseAsset: {
        address: 'Ethereum',
        abi: null,
        symbol: 'ETH',
        name: 'Ether',
        decimals: 18,
        balance: 0,
        logo: 'ETH-logo.png',
        type: 'base'
      },
      liquidityAsset: {
        address: config.liquidityAddress,
        abi: LiquidityABI,
        symbol: 'LBI',
        name: 'Liquidity Income',
        decimals: 18,
        balance: 0,
        logo: 'LBI-logo.png',
        type: 'liquidity'
      },
      poolAsset: {
        address: config.poolAddress,
        abi: PoolABI,
        symbol: 'UNI-V2',
        name: 'Uniswap V2',
        decimals: 18,
        balance: 0,
        logo: 'UNI-logo.png',
        type: 'pool'
      },
      rewardAsset: {
        address: config.rewardsAddress,
        abi: RewardsABI,
        symbol: 'WDN',
        name: 'Liquidity Income Delegate',
        decimals: 18,
        balance: 0,
        logo: 'LBD-logo.png',
        type: 'reward'
      },
      wardenAsset: {
        address: config.wardenAddress,
        abi: WardenABI,
        symbol: 'WD3N',
        name: 'Warden',
        decimals: 18,
        balance: 0,
        extendedBalance: 0,
        balanceWD3NV2:0,
        extendedBalanceWD3NV2:0,
        contract:null,
        contractLegacy:null,
        logo: 'KPR-logo.png',
        type: 'warden',
        bonds: 0,
        pendingBonds: 0,
        bondings: 0,
        bondingDelay:0,
        unbondings: 0,
        unbondingDelay:0,
        blacklisted: false,
        disputed: false,
        lastJob: 0,
        firstSeen: 0,
        delegates: false,
        work: 0,
        workCompleted: 0,
        isActive: false,
        voteSymbol: 'WDN',
        currentVotes: 0
      },
      jobs: [

      ],
      wardens: [

      ],
      governance: {
        address: ""
      }
    }

    dispatcher.register(
      function (payload) {
        switch (payload.type) {
          case GET_BALANCES:
            this.getBalances(payload);
            break;
          case GET_GAS_PRICES:
            this.getGasPrices(payload);
            break;
          case PROPOSE:
            this.propose(payload);
            break;
          case SIGN_ACTION:
            this.signAction(payload);
            break;
          case CALCULATE_MINT:
            this.calculateMint(payload);
            break;
          case MINT:
            this.mint(payload);
            break;
          case STAKE:
            this.stake(payload);
            break;
          case GET_PROPOSALS:
            this.getProposals(payload);
            break;
          case GET_CURRENT_BLOCK:
            this.getCurrentBlock(payload);
            break;
          case GET_REWARDS_AVAILABLE:
            this.getRewardsAvailable(payload);
            break;
          case GET_REWARD:
            this.getReward(payload);
            break;
          case VOTE_FOR:
            this.voteFor(payload);
            break;
          case VOTE_AGAINST:
            this.voteAgainst(payload);
            break;

          case GET_WARDEN:
            this.getWarden(payload);
            break;
          case GET_JOBS:
            this.getJobs(payload);
            break;
          case GET_WARDENS:
            this.getWardens(payload);
            break;
          case ADD_BOND:
            this.addBond(payload);
            break;
          case REMOVE_BOND:
            this.removeBond(payload);
            break;
          case ACTIVATE_BOND:
            this.activateBond(payload);
            break;
          case WITHDRAW_BOND:
            this.withdrawBond(payload);
            break;
          case SWAP_APPROVED:
            this.getIfApprovedForSwap();
            break;
          case SWAP_APPROVE:
            this.approveSwap();
            break;
          case SWAP_EXECUTE:
            this.executeSwap();
            break;
          case GET_WARDEN_PROFILE:
            this.getWardenProfile(payload);
            break;
          case ADD_JOB:
            this.addJob(payload);
            break;
          case GET_JOB_PROFILE:
            this.getJobProfile(payload);
            break;
          case ADD_LIQUIDITY_TO_JOB:
            this.addLiquidityToJob(payload);
            break;
          case REMOVE_LIQUIDITY_FROM_JOB:
            this.removeLiquidityFromJob(payload);
            break;
          case APPLY_CREDIT_TO_JOB:
            this.applyCreditToJob(payload);
            break;
          case UNBOND_LIQUIDITY_FROM_JOB:
            this.unbondLiquidityFromJob(payload);
            break;
          case SLASH:
            this.slash(payload);
            break;
          case TRANSFER_RIGHTS:
            this.transferRights(payload);
            break;
          case ADD_CREDITS:
            this.addCredits(payload);
            break;
          case GET_GOVERNANCE_ADDRESS:
            this.getGovernanceAddress(payload)
            break;
          default: {
          }
        }
      }.bind(this)
    );
  }

  getStore(index) {
    return(this.store[index]);
  };

  setStore(obj) {
    this.store = {...this.store, ...obj}
    return emitter.emit('StoreUpdated');
  };

  getBalances = async () => {
    const account = store.getStore('account')

    let baseAsset = store.getStore('baseAsset')
    let liquidityAsset = store.getStore('liquidityAsset')
    let rewardAsset = store.getStore('rewardAsset')
    // let poolAsset = store.getStore('poolAsset')
    let wardenAsset = store.getStore('wardenAsset')

    let assets = [baseAsset, liquidityAsset, rewardAsset, wardenAsset] // ,poolAsset

    if(!account || !account.address || !assets) {
      return false
    }

    const web3 = await this._getWeb3Provider();

    async.map(assets, (asset, callback) => {
      async.parallel([
        (callbackInner) => { this._getBalance(web3, asset, account, callbackInner) },
      ], (err, data) => {

        asset.balance = data[0]

        callback(null, asset)
      })
    }, (err, resultAssets) => {
      if(err) {
        return emitter.emit(ERROR, err)
      }

      baseAsset = resultAssets[0]
      liquidityAsset = resultAssets[1]
      rewardAsset = resultAssets[2]
      // poolAsset = resultAssets[3]
      wardenAsset = resultAssets[4]

      store.setStore({ baseAsset: baseAsset, liquidityAsset: liquidityAsset, rewardAsset: rewardAsset }) //, poolAsset: poolAsset
      return emitter.emit(BALANCES_RETURNED)
    })
  }

  _getBalance = async (web3, asset, account, callback) => {
    try {
      if(asset.address === 'Ethereum') {
        const eth_balance = web3.utils.fromWei(await web3.eth.getBalance(account.address), "ether");
        callback(null, parseFloat(eth_balance))
      } else {
        const assetContract = new web3.eth.Contract(asset.abi, asset.address)

        let balance = await assetContract.methods.balanceOf(account.address).call({ from: account.address });
        balance = parseFloat(balance)/10**asset.decimals
        callback(null, parseFloat(balance))
      }
    } catch(ex) {
      console.log(ex)
      return callback(ex)
    }
  }


  calculateMint = async (payload) => {
    try {
      const account = store.getStore('account')
      const baseAsset = store.getStore('baseAsset')
      const liquidityAsset = store.getStore('liquidityAsset')
      const web3 = await this._getWeb3Provider();

      const { amount } = payload.content

      const liquidityContract = new web3.eth.Contract(LiquidityABI, liquidityAsset.address)

      let amountToSend = (amount*10**baseAsset.decimals).toFixed(0);

      const output = await liquidityContract.methods.calculateMint(amountToSend).call({ from: account.address })
      const receiveAmount = parseFloat(output)/10**liquidityAsset.decimals

      return emitter.emit(CALCULATE_MINT_RETURNED, receiveAmount)
    } catch(ex) {
      console.log(ex)
    }
  }

  mint = (payload) => {
    const account = store.getStore('account')
    const baseAsset = store.getStore('baseAsset')
    const { amount } = payload.content

    this._callMint(baseAsset, account, amount, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, MINT);
      }

      return emitter.emit(MINT_RETURNED, res)
    })
  }

  _callMint = async (asset, account, amount, callback) => {
    const web3 = await this._getWeb3Provider();
    const amountToSend = (amount*10**asset.decimals).toFixed(0)

    const liquidityContract = new web3.eth.Contract(LiquidityABI, config.liquidityAddress)
    const output = await liquidityContract.methods.calculateMint(amountToSend).call({ from: account.address })

    liquidityContract.methods.mint(output).send({ value: amountToSend, from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  /*getIfApprovedForSwap = async () => {
    let wardenAsset = store.getStore('wardenAsset')
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider()
    if(!web3) {
      emitter.emit(SWAP_APPROVE_RETURNED, {})
      return
    }
    else{
      let wardenData = await this._getWardenData(web3, wardenAsset, account.address)
      wardenData.contractLegacy.address = wardenData.contractLegacy._address
      this._checkApproval(wardenData.contractLegacy, account, wardenData.extendedBalanceWD3NV2, config.swapAddress, (err) => {
        if(err) {
          console.error(err)
          emitter.emit(SNACKBAR_ERROR, err)
          return emitter.emit(ERROR, SWAP_APPROVED)
        }
        else
          return emitter.emit(SWAP_APPROVE_RETURNED,null)
      })
    }
  }

  stake = (payload) => {
    const account = store.getStore('account')
    const liquidityAsset = store.getStore('liquidityAsset')
    const { amount } = payload.content

    this._checkApproval(liquidityAsset, account, amount, config.rewardsAddress, (err) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err)
        return emitter.emit(ERROR, STAKE)
      }

      this._callStake(liquidityAsset, account, amount, (err, res) => {
        if(err) {
          emitter.emit(SNACKBAR_ERROR, err)
          return emitter.emit(ERROR, STAKE)
        }

        return emitter.emit(STAKE_RETURNED, res)
      })
    })
  }

  _callStake = async (asset, account, amount, callback) => {
    const web3 = await this._getWeb3Provider();
    const amountToSend = (amount*10**asset.decimals).toFixed(0)

    const rewardsContract = new web3.eth.Contract(RewardsABI, config.rewardsAddress)
    rewardsContract.methods.stake(amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }*/


  _checkApproval = async (asset, account, amount, contract, callback) => {
    const web3 = await this._getWeb3Provider()
    try {
      const erc20Contract = new web3.eth.Contract(ERC20ABI, asset.address)
      const allowance = await erc20Contract.methods.allowance(account.address, contract).call({ from: account.address })

      const ethAllowance = (allowance*10**asset.decimals).toFixed(0)
      let amountToSend = web3.utils.toWei('999999999', "ether");
      if(asset.decimals !== 18) {
        amountToSend = (999999999*10**asset.decimals).toFixed(0)
      }

      if(parseFloat(ethAllowance) < parseFloat(amount)) {
        await erc20Contract.methods.approve(contract, amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
        callback()
      } else {
        callback()
      }

    } catch(error) {
      if(error.message) {
        return callback(error.message)
      }
      callback(error)
    }
  }

  getGasPrices = async (payload) => {
    const gasPrices = await this._getGasPrices()  
    let gasSpeed = localStorage.getItem('governance-gas-speed')

    if(!gasSpeed) {
      gasSpeed = 'fast'
      localStorage.getItem('governance-gas-speed', 'fast')
    }

    store.setStore({ gasPrices: gasPrices, gasSpeed: gasSpeed })
    emitter.emit(GAS_PRICES_RETURNED)
  }

  _getGasPrices = async () => {
    try {
      const url = config.gasPriceURL
      const priceString = await rp(url);
      const priceJSON = JSON.parse(priceString)
      if(priceJSON) {
        return priceJSON
      }
    } catch(e) {
      console.log(e)
      return {}
    }
  }

  _getGasPrice = async () => {
    const gasSpeed = store.getStore('gasSpeed')

    try {
      const url = config.gasPriceURL
      const priceString = await rp(url);
      const priceJSON = JSON.parse(priceString)
      if(priceJSON) {
        return priceJSON[gasSpeed].toFixed(0)
      }
    } catch(e) {
      console.log(e)
      return {}
    }
  }

  _getWeb3Provider = async () => {

    const web3context = store.getStore('web3context');
    if(!web3context) {
      return null
    }
    const provider = web3context.library.provider
    if(!provider) {
      return null
    }

    const web3 = new Web3(provider);

    return web3
  }

  signAction = async (payload) => {
    const { contract, func, params, paramValues } = payload.content
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider();
    if(!web3) return

    this._signActionBySig(web3, account, contract, func, params, paramValues, (err, result) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, SIGN_ACTION);
      }

      const retObj = {
        contract,
        func,
        result
      }

      return emitter.emit(SIGN_ACTION_RETURNED, retObj)
    })
  }

  _signActionBySig = async (web3, account, contract, func, params, paramValues, callback) => {

    const domain = [
      { name: "name", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" }
    ];

    const parameters = params.map((param) => {
      return {
        name: param.name,
        type: param.type
      }
    });

    const chainId = web3.currentProvider.networkVersion;

    const domainData = {
      name: func,
      version: "1",
      chainId: chainId,
      verifyingContract: contract.address
    };

    //map params to values
    let message = {}

    let i = 0
    for(i = 0; i < params.length; i++) {
      message[params[i].name] = paramValues[i]
    }

    const data = JSON.stringify({
      types: {
        EIP712Domain: domain,
        Params: parameters
      },
      domain: domainData,
      primaryType: "Params",
      message: message
    });


    const signer = web3.utils.toChecksumAddress(account.address);

    const that = this

    web3.currentProvider.sendAsync(
      {
        method: "eth_signTypedData_v3",
        params: [signer, data],
        from: signer
      },
      function(err, result) {
        if (err || result.error) {
          console.error(err || result.error);
          if(result && result.error && result.error.message) {
            return callback(result.error.message)
          }
          return callback(err || result.error)
        }

        const signature = that._parseSignature(result.result.substring(2));

        // const returnObj = {
        //   signer: signer,
        //   message: message,
        //   r: signature.r,
        //   s: signature.s,
        //   v: signature.v
        // }

        callback(null, signature)
      }
    );
  }

  _parseSignature = (signature) => {
    var r = signature.substring(0, 64);
    var s = signature.substring(64, 128);
    var v = signature.substring(128, 130);

    return {
        r: "0x" + r,
        s: "0x" + s,
        v: parseInt(v, 16)
    }
  }

  propose = async (payload) => {
    const { contracts, description } = payload.content
    const web3 = await this._getWeb3Provider()

    const targets = contracts.map((contract) => {
      return contract.address
    })
    const values = contracts.map((contract) => {
      return contract.value
    })
    const signatures = contracts.map((contract) => {
      const theContract = new web3.eth.Contract(contract.abi, contract.address)

      const signature = theContract.methods[contract.func]( ...contract.paramValues )._method.signature;
      return signature
    })
    const calldatas = contracts.map((contract) => {
      const theContract = new web3.eth.Contract(contract.abi, contract.address)
      const callData = theContract.methods[contract.func]( ...contract.paramValues ).encodeABI();
      return callData
    })

    this._callPropose(targets, values, signatures, calldatas, description, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, PROPOSE);
      }

      return emitter.emit(PROPOSE_RETURNED, res)
    })
  }

  _callPropose = async (targets, values, signatures, calldatas, description, callback) => {
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider()

    console.log(targets)
    console.log(values)
    console.log(signatures)
    console.log(calldatas)
    console.log(description)

    const governanceContract = new web3.eth.Contract(GovernanceABI, config.governanceAddress)
    governanceContract.methods.propose(targets, values, signatures, calldatas, description).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 1) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  getProposals = async (payload) => {
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider()

    if(!web3) {
      emitter.emit(GET_PROPOSALS_RETURNED)
      return
    }

    this._getProposalCount(web3, account, (err, proposalCount) => {
      if(err) {
        return emitter.emit(ERROR, err);
      }

      let arr = Array.from(Array(parseInt(proposalCount)).keys()).reverse()

      if(proposalCount === 0 || proposalCount === '0') {
        arr = []
      }

      async.map(arr, (proposal, callback) => {
        proposal = proposal + 1
        this._getProposals(web3, account, proposal, callback)
      }, (err, proposalsData) => {
        if(err) {
          return emitter.emit(ERROR, err);
        }

        store.setStore({ proposals: proposalsData })
        emitter.emit(GET_PROPOSALS_RETURNED)
      })
    })
  }

  _getProposalCount = async (web3, account, callback) => {
    try {
      const governanceContract = new web3.eth.Contract(GovernanceABI, config.governanceAddress)
      const proposals = await governanceContract.methods.proposalCount().call({ from: account.address });
      callback(null, proposals)
    } catch(ex) {
      console.log(ex)
      return callback(ex)
    }
  }

  _getProposals = async (web3, account, number, callback) => {
    try {
      const governanceContract = new web3.eth.Contract(GovernanceABI, config.governanceAddress)
      let proposal = await governanceContract.methods.proposals(number).call({ from: account.address });
      const receipt = await governanceContract.methods.getReceipt(number, account.address).call({ from: account.address });
      const actions = await governanceContract.methods.getActions(number).call({ from: account.address });
      let state = await governanceContract.methods.state(number).call({ from: account.address });

      proposal.receipt = receipt
      proposal.actions = actions
      proposal.state = state
      proposal.stateDescription = this._getState(state)

      callback(null, proposal)
    } catch(ex) {
      return callback(ex)
    }
  }

  _getState = (num) => {
    switch (num) {
      case '0':
        return "Pending"
      case '1':
        return "Active"
      case '2':
        return "Canceled"
      case '3':
        return "Defeated"
      case '4':
        return "Succeeded"
      case '5':
        return "Queued"
      case '6':
        return "Expired"
      case '7':
        return "Executed"
      default:
        return "Pending"
    }
  }

  getCurrentBlock = async () => {
    const web3 = await this._getWeb3Provider()

    if(!web3) {
      emitter.emit(CURRENT_BLOCK_RETURNED)
      return
    }

    const currentBlock = await web3.eth.getBlockNumber()

    store.setStore({ currentBlock: currentBlock })

    window.setTimeout(() => {
      emitter.emit(CURRENT_BLOCK_RETURNED)
    }, 100)
  }

  getRewardsAvailable = async () => {
    try {
      const account = store.getStore('account')
      const rewardAsset = store.getStore('rewardAsset')
      const web3 = await this._getWeb3Provider();

      const rewardsContract = new web3.eth.Contract(RewardsABI, rewardAsset.address)

      const output = await rewardsContract.methods.rewards(account.address).call({ from: account.address })
      const rewardAmount = parseFloat(output)/10**rewardAsset.decimals

      return emitter.emit(REWARDS_AVAILABLE_RETURNED, rewardAmount)
    } catch(ex) {
      console.log(ex)
      emitter.emit(SNACKBAR_ERROR, ex);
      return emitter.emit(ERROR, GET_REWARDS_AVAILABLE)
    }
  }

  getReward = (payload) => {
    const account = store.getStore('account')
    const baseAsset = store.getStore('baseAsset')
    const { amount } = payload.content

    this._callGetReward(baseAsset, account, amount, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, GET_REWARD);
      }

      return emitter.emit(REWARD_RETURNED, res)
    })
  }

  _callGetReward = async (asset, account, amount, callback) => {
    const web3 = await this._getWeb3Provider();

    const rewardsContract = new web3.eth.Contract(RewardsABI, config.rewardsAddress)

    rewardsContract.methods.getReward().send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  voteFor = (payload) => {
    const account = store.getStore('account')
    const { proposal } = payload.content

    this._callVoteFor(proposal, account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, GET_REWARD);
      }

      return emitter.emit(VOTE_FOR_RETURNED, res)
    })
  }

  _callVoteFor = async (proposal, account, callback) => {
    const web3 = await this._getWeb3Provider();

    const governanceContract = new web3.eth.Contract(GovernanceABI, config.governanceAddress)

    governanceContract.methods.castVote(proposal.id, true).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  voteAgainst = (payload) => {
    const account = store.getStore('account')
    const { proposal } = payload.content

    this._callVoteAgainst(proposal, account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, GET_REWARD);
      }

      return emitter.emit(VOTE_AGAINST_RETURNED, res)
    })
  }

  _callVoteAgainst = async (proposal, account, callback) => {
    const web3 = await this._getWeb3Provider();

    const governanceContract = new web3.eth.Contract(GovernanceABI, config.governanceAddress)

    governanceContract.methods.castVote(proposal.id, false).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }


  getWarden = async (payload) => {
    const account = store.getStore('account')
    let wardenAsset = store.getStore('wardenAsset')
    const web3 = await this._getWeb3Provider()
    if(!web3) {
      emitter.emit(WARDEN_RETURNED)
      return
    }

    try {

      wardenAsset = await this._getWardenData(web3, wardenAsset, account.address)

      store.setStore({ wardenAsset: wardenAsset })

      emitter.emit(WARDEN_RETURNED)

    } catch(ex) {
      emitter.emit(SNACKBAR_ERROR, ex)
      return emitter.emit(ERROR, GET_WARDEN)
    }
  }

  _getWardenData = async (web3, wardenAsset, address) => {

    try {
      const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)
      const wardenContractLegacy = new web3.eth.Contract(WardenABI, config.wardenAddressLegacy)

      wardenAsset.contract = wardenContract;
      wardenAsset.contractLegacy =wardenContractLegacy;
      let balance = await wardenContract.methods.balanceOf(address).call({ })
      balance = balance/10**wardenAsset.decimals
      wardenAsset.balance = balance
      wardenAsset.extendedBalance = await wardenContract.methods.balanceOf(address).call({ })

      //Get WD3NV2 balance
      let balanceWD3NV2 = await wardenContractLegacy.methods.balanceOf(address).call({ })
      wardenAsset.balanceWD3NV2 =balanceWD3NV2/10**wardenAsset.decimals
      wardenAsset.extendedBalanceWD3NV2 = balanceWD3NV2

      let bonds = await wardenContract.methods.bonds(address, wardenAsset.address).call({ })
      bonds = bonds/10**wardenAsset.decimals
      wardenAsset.bonds = bonds

      let pendingBonds = await wardenContract.methods.pendingbonds(address, wardenAsset.address).call({ })
      pendingBonds = pendingBonds/10**wardenAsset.decimals
      wardenAsset.pendingBonds = pendingBonds

      let partialUnbonding = await wardenContract.methods.partialUnbonding(address, wardenAsset.address).call({ })
      partialUnbonding = partialUnbonding/10**wardenAsset.decimals
      wardenAsset.partialUnbonding = partialUnbonding

      wardenAsset.bondings = await wardenContract.methods.bondings(address, wardenAsset.address).call({ })
      wardenAsset.bondingDelay = await wardenContract.methods.BOND().call()
      wardenAsset.bondings = (parseInt(wardenAsset.bondings)  + parseInt(wardenAsset.bondingDelay)).toString()

      wardenAsset.unbondings = await wardenContract.methods.unbondings(address, wardenAsset.address).call({ })
      wardenAsset.unbondingDelay = await wardenContract.methods.UNBOND().call()
      wardenAsset.unbondings = (parseInt(wardenAsset.unbondings)  + parseInt(wardenAsset.unbondingDelay)).toString()

      wardenAsset.blacklisted = await wardenContract.methods.blacklist(address).call({ })
      wardenAsset.disputed = await wardenContract.methods.disputes(address).call({ })
      wardenAsset.firstSeen = await wardenContract.methods.firstSeen(address).call({ })
      wardenAsset.delegates = await wardenContract.methods.delegates(address).call({ })

      wardenAsset.workCompleted = await wardenContract.methods.workCompleted(address).call({ })
      wardenAsset.workCompleted = wardenAsset.workCompleted/10**wardenAsset.decimals

      wardenAsset.lastJob = await wardenContract.methods.lastJob(address).call({ })

      wardenAsset.isActive = await wardenContract.methods.wardens(address).call({ })

      let currentVotes = await wardenContract.methods.getCurrentVotes(address).call({ })
      currentVotes = currentVotes/10**wardenAsset.decimals
      wardenAsset.currentVotes = currentVotes

      return wardenAsset
    } catch(ex) {
      console.log(ex)
      return {}
    }
  }

  getJobs = async (payload) => {
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider()
    const wardenAsset = store.getStore('wardenAsset')

    if(!web3) {
      emitter.emit(JOBS_RETURNED)
      return
    }

    try {
      const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)
      const jobs = await wardenContract.methods.getJobs().call({ from: account.address });

      async.map(jobs, async (job, callback) => {
        let jobProfile = await this._getJobData(web3, wardenAsset, job)
        jobProfile.address = job

        if(callback) {
          callback(null, jobProfile)
        } else {
          return jobProfile
        }
      }, (err, jobsData) => {
        if(err) {
          emitter.emit(SNACKBAR_ERROR, err);
          return emitter.emit(ERROR, GET_JOBS);
        }

        store.setStore({ jobs: jobsData })
        emitter.emit(JOBS_RETURNED)
      })

    } catch(ex) {
      emitter.emit(SNACKBAR_ERROR, ex)
      return emitter.emit(ERROR, GET_JOBS)
    }
  }

  getWardens = async (payload) => {
    const account = store.getStore('account')
    const web3 = await this._getWeb3Provider()
    if(!web3) {
      emitter.emit(WARDENS_RETURNED)
      return
    }

    try {
      const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

      const wardens = await wardenContract.methods.getWardens().call({ from: account.address })

      store.setStore({ wardens: wardens })

      emitter.emit(WARDENS_RETURNED)
    } catch(ex) {
      emitter.emit(SNACKBAR_ERROR, ex)
      return emitter.emit(ERROR, GET_WARDENS)
    }
  }

  addBond = (payload) => {
    const account = store.getStore('account')
    const { amount } = payload.content

    this._callBond(amount, account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, ADD_BOND);
      }

      return emitter.emit(ADD_BOND_RETURNED, res)
    })
  }

  _callBond = async (amount, account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    let amountToSend = (amount*10**wardenAsset.decimals).toFixed(0);

    wardenContract.methods.bond(wardenAsset.address, amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  removeBond = (payload) => {
    const account = store.getStore('account')
    const { amount } = payload.content

    this._callUnbond(amount, account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, REMOVE_BOND);
      }

      return emitter.emit(REMOVE_BOND_RETURNED, res)
    })
  }

  _callUnbond = async (amount, account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    let amountToSend = (amount*10**wardenAsset.decimals).toFixed(0);

    wardenContract.methods.unbond(wardenAsset.address, amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  activateBond = (payload) => {
    const account = store.getStore('account')

    this._callActivate(account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, ACTIVATE_BOND);
      }

      return emitter.emit(ACTIVATE_BOND_RETURNED, res)
    })
  }

  _callActivate = async (account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    wardenContract.methods.activate(wardenAsset.address).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  withdrawBond = (payload) => {
    const account = store.getStore('account')

    this._callWithdraw(account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, WITHDRAW_BOND);
      }

      return emitter.emit(WITHDRAW_BOND_RETURNED, res)
    })
  }

  approveSwap = () => {
    const account = store.getStore('account')

    this._callApproveSwap(account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, SWAP_APPROVE);
      }

      return emitter.emit(SWAP_APPROVE_RETURNED, res)
    })
  }

  executeSwap = () => {
    const account = store.getStore('account')

    this._callSwapExecute(account, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, SWAP_EXECUTE);
      }

      return emitter.emit(SWAP_EXECUTE_RETURNED, res)
    })
  }

    _callSwapExecute = async (account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const swapContract = new web3.eth.Contract(SwaperAbi, config.swapAddress)

    //Swap all WD3NV2 to WD3N
    swapContract.methods.swapTokens(wardenAsset.extendedBalanceWD3NV2).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  _callApproveSwap = async (account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddressLegacy)
    //Approve swap contract to swap tokens for you
    wardenContract.methods.approve(config.swapAddress,wardenAsset.extendedBalanceWD3NV2).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  _callWithdraw = async (account, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)


    wardenContract.methods.withdraw(wardenAsset.address).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  getWardenProfile = async (payload) => {
    const wardenAsset = store.getStore('wardenAsset')
    const web3 = await this._getWeb3Provider()
    if(!web3) {
      emitter.emit(WARDEN_PROFILE_RETURNED, {})
      return
    }

    try {

      let wardenProfile = await this._getWardenData(web3, wardenAsset, payload.content.address)
      wardenProfile.profileAddress = payload.content.address

      emitter.emit(WARDEN_PROFILE_RETURNED, wardenProfile)

    } catch(ex) {
      emitter.emit(SNACKBAR_ERROR, ex)
      return emitter.emit(ERROR, GET_WARDEN_PROFILE)
    }
  }

  addJob = async (payload) => {
    const account = store.getStore('account')

    const { address, addLiquidityAmount, name, docs, ipfs } = payload.content

    this._callAddLiquidityToJob(account, address, addLiquidityAmount, async (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, ADD_JOB);
      }

      const governanceAddress = await this._getGovernanceAddress()
      if(governanceAddress.toLowerCase() === account.address.toLowerCase() && (name !== '' || docs !== '' || ipfs !== '')) {
        this._callAdd(account, address, name, docs, ipfs, (err, res) => {
          if(err) {
            emitter.emit(SNACKBAR_ERROR, err);
            return emitter.emit(ERROR, ADD_JOB);
          }

          return emitter.emit(ADD_JOB_RETURNED, res)
        })

      } else {
        return emitter.emit(ADD_JOB_RETURNED, res)
      }
    })
  }

  getGovernanceAddress = async (payload) => {
    const governance = store.getStore("governance");
    if (governance.address){
      emitter.emit(GET_GOVERNANCE_ADDRESS_RETURNED, governance.address)
    } else {
      const address = await this._getGovernanceAddress();
      if (address){
        store.setStore({governance: {address}});
        emitter.emit(GET_GOVERNANCE_ADDRESS_RETURNED, address)
      } else {
        emitter.emit(ERROR, GET_GOVERNANCE_ADDRESS);
      }
    }
  }

  _getGovernanceAddress = async () => {
    try {
      const web3 = await this._getWeb3Provider();
      const jobRegistryContract = new web3.eth.Contract(JobRegistryABI, config.jobRegistryAddress)

      return await jobRegistryContract.methods.governance().call({})
    } catch(ex) {
      console.log(ex)
      return null
    }
  }

  _callAdd = async (account, address, name, docs, ipfs, callback) => {
    const web3 = await this._getWeb3Provider();

    const jobRegistryContract = new web3.eth.Contract(JobRegistryABI, config.jobRegistryAddress)

    jobRegistryContract.methods.add(address, name, ipfs, docs).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  _callAddLiquidityToJob = async (account, address, amount, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    let amountToSend = (amount*10**wardenAsset.decimals).toFixed(0);

    wardenContract.methods.addLiquidityToJob(wardenAsset.address, address, amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  getJobProfile = async (payload) => {
    let wardenAsset = store.getStore('wardenAsset')
    const web3 = await this._getWeb3Provider()
    if(!web3) {
      emitter.emit(JOB_PROFILE_RETURNED, {})
      return
    }

    try {

      let jobProfile = await this._getJobData(web3, wardenAsset, payload.content.address)
      jobProfile.address = payload.content.address

      emitter.emit(JOB_PROFILE_RETURNED, jobProfile)

    } catch(ex) {
      emitter.emit(SNACKBAR_ERROR, ex)
      return emitter.emit(ERROR, GET_JOB_PROFILE)
    }
  }

  _getJobData = async (web3, wardenAsset, address) => {
    try {
      const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)
      const jobRegistryContract = new web3.eth.Contract(JobRegistryABI, config.jobRegistryAddress)
      const jobContract = new web3.eth.Contract(OwnableABI, address)

      const isJob = await wardenContract.methods.jobs(address).call({ })
      if(!isJob) {
        return {
          isJob: false
        }
      }

      let jobProfile = await jobRegistryContract.methods.jobData(address).call({ })
      if(!jobProfile) {
        jobProfile = {}
      }
      jobProfile.owner = await jobContract.methods.owner().call({ });
      jobProfile.isLpFunded = false;
      jobProfile.jobAdded = await jobRegistryContract.methods.jobAdded(address).call({})

      let credits = await wardenContract.methods.credits(address, wardenAsset.address).call({ })
      credits = credits/10**wardenAsset.decimals

      jobProfile.credits = credits
      jobProfile.isJob = isJob

      if(jobProfile._docs) {
        jobProfile.fileContent = await this._getGithubFile(jobProfile._docs, address)
      }

      return jobProfile
    } catch(ex) {
      console.log(ex)
      return {
        credits: 0
      }
    }
  }

  _getGithubFile = async (documentation, address) => {
    if(documentation && documentation.includes('https://github.com')) {
      try {

        const documentationPieces = documentation.split('/')

        const user = documentationPieces[3]
        const repository = documentationPieces[4]
        const branch = documentationPieces[6]
        const newArr = documentationPieces.slice(7)

        const file = newArr.join('/')
        const url = `${config.githubAPI}${user}/${repository}/${branch}/${file}`

        const rawCode = await rp(url);
        if(rawCode) {
          return rawCode
        }
      } catch(e) {
        console.log(e)
          return null
      }
    } else if (documentation && documentation.includes('https://etherscan.io/')) {
      try {

        const url = `${config.etherscanAPI}?module=contract&action=getsourcecode&address=${address}&apikey=${config.etherscanAPIKey}`

        const rawCode = await rp(url);
        if(rawCode) {
          const jsonCode = JSON.parse(rawCode)
          if(jsonCode.result && jsonCode.result.length > 0) {
            return jsonCode.result[0].SourceCode
          } else {
            return jsonCode
          }
        }
      } catch(e) {
        console.log(e)
          return null
      }
    } else {
      return null
    }

  }


  addLiquidityToJob = async (payload) => {
    const account = store.getStore('account')

    const { address, addLiquidityAmount } = payload.content

    this._callAddLiquidityToJob(account, address, addLiquidityAmount, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, ADD_LIQUIDITY_TO_JOB);
      }

      return emitter.emit(ADD_LIQUIDITY_TO_JOB_RETURNED, res)
    })
  }

  applyCreditToJob = async (payload) => {
    const account = store.getStore('account')

    const { address } = payload.content

    this._callApplyCreditToJob(account, address, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, APPLY_CREDIT_TO_JOB);
      }

      return emitter.emit(APPLY_CREDIT_TO_JOB_RETURNED, res)
    })
  }

  _callApplyCreditToJob = async (account, address, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    wardenContract.methods.applyCreditToJob(account.address, wardenAsset.address, address).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  unbondLiquidityFromJob = async (payload) => {
    const account = store.getStore('account')

    const { address, removeLiquidityAmount } = payload.content

    this._callUnbondLiquidityFromJob(account, address, removeLiquidityAmount, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, UNBOND_LIQUIDITY_FROM_JOB);
      }

      return emitter.emit(UNBOND_LIQUIDITY_FROM_JOB_RETURNED, res)
    })
  }

  _callUnbondLiquidityFromJob = async (account, address, amount, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    let amountToSend = (amount*10**wardenAsset.decimals).toFixed(0);

    wardenContract.methods.unbondLiquidityFromJob(wardenAsset.address, address, amountToSend).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  removeLiquidityFromJob = async (payload) => {
    const account = store.getStore('account')

    const { address } = payload.content

    this._callRemoveLiquidityFromJob(account, address, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, REMOVE_LIQUIDITY_FROM_JOB);
      }

      return emitter.emit(REMOVE_LIQUIDITY_FROM_JOB_RETURNED, res)
    })
  }

  _callRemoveLiquidityFromJob = async (account, address, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = store.getStore('wardenAsset')

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    wardenContract.methods.removeLiquidityFromJob(wardenAsset.address, address).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  slash = async (payload) => {
    const account = store.getStore('account')

    const { address } = payload.content

    this._callDown(account, address, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, SLASH);
      }

      return emitter.emit(SLASH_RETURNED, res)
    })
  }

  _callDown = async (account, address, callback) => {
    const web3 = await this._getWeb3Provider();

    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)

    wardenContract.methods.down(address).send({ from: account.address, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
      .on('transactionHash', function(hash){
        emitter.emit(TX_SUBMITTED, hash)
        callback(null, hash)
      })
      .on('confirmation', function(confirmationNumber, receipt){
        if(confirmationNumber === 2) {
          emitter.emit(TX_CONFIRMED, receipt.transactionHash)
        }
      })
      .on('receipt', function(receipt){
        emitter.emit(TX_RECEIPT, receipt.transactionHash)
      })
      .on('error', function(error) {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if(error.message) {
            return callback(error.message)
          }
          callback(error)
        }
      })
  }

  transferRights = (payload) => {
    const account = store.getStore('account')
    const { to } = payload.content
    this._callTransferRights(account.address, to, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, TRANSFER_RIGHTS);
      }

      return emitter.emit(TRANSFER_RIGHTS_RETURNED, res)
    })
  }

  _callTransferRights = async (from, to, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenAsset = this.getStore('wardenAsset')
    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress)
    wardenContract.methods.transferWardenRight(wardenAsset.address, from, to).send({ from, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
        .on('transactionHash', function(hash){
          emitter.emit(TX_SUBMITTED, hash)
          callback(null, hash)
        })
        .on('confirmation', function(confirmationNumber, receipt){
          if(confirmationNumber === 2) {
            emitter.emit(TX_CONFIRMED, receipt.transactionHash)
          }
        })
        .on('receipt', function(receipt){
          emitter.emit(TX_RECEIPT, receipt.transactionHash)
        })
        .on('error', function(error) {
          if (!error.toString().includes("-32601")) {
            if(error.message) {
              return callback(error.message)
            }
            callback(error)
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if(error.message) {
              return callback(error.message)
            }
            callback(error)
          }
        })
  }

  addCredits = (payload) => {
    //_getGovernanceAddress()
    const {address, amount} = payload.content;
    this._addCredits(address, amount, (err, res) => {
      if(err) {
        emitter.emit(SNACKBAR_ERROR, err);
        return emitter.emit(ERROR, ADD_CREDITS);
      }

      return emitter.emit(ADD_CREDITS_RETURNED, res);
    });

  }

  _addCredits = async (address, amount, callback) => {
    const web3 = await this._getWeb3Provider();
    const wardenContract = new web3.eth.Contract(WardenABI, config.wardenAddress);
    const account = store.getStore('account').address;
    const wardenAddress = store.getStore('wardenAsset').address;
    wardenContract.methods.addCredit(wardenAddress, address, web3.utils.toWei(amount, 'ether'))
        .send({ from: account, gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei') })
        .on('transactionHash', function(hash){
          emitter.emit(TX_SUBMITTED, hash)
          callback(null, hash)
        })
        .on('confirmation', function(confirmationNumber, receipt){
          if(confirmationNumber === 2) {
            emitter.emit(TX_CONFIRMED, receipt.transactionHash)
          }
        })
        .on('receipt', function(receipt){
          emitter.emit(TX_RECEIPT, receipt.transactionHash)
        })
        .on('error', function(error) {
          if (!error.toString().includes("-32601")) {
            if(error.message) {
              return callback(error.message)
            }
            callback(error)
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if(error.message) {
              return callback(error.message)
            }
            callback(error)
          }
        })
  }

}

var store = new Store();

export default {
  store: store,
  dispatcher: dispatcher,
  emitter: emitter
};
