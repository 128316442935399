require('dotenv').config()

const config = {
  infuraProvider: process.env.REACT_APP_PROVIDER,
  gasPriceURL: "https://gasprice.poa.network/",
  githubAPI: process.env.REACT_APP_GITHUB_API,
  etherscanAPI: process.env.REACT_APP_ETHERSCAN_API,
  etherscanAPIKey: process.env.REACT_APP_ETHERSCAN_KEY,

  liquidityAddress: '0x375Da3e307Ef2E1A9D9e1516f80738Ca52cb7B85',
  governanceAddress: '0x41bc369A7eC23b7169A1FC7a4c36cbEf51a1AeED',
  rewardsAddress: '0x48468708823FA407580e0cCE107c11679e48E9D9',

  wardenAddress: '0x48468708823FA407580e0cCE107c11679e48E9D9',
  wardenAddressLegacy :'0xC415eccA92be23fb5edF33377E3e4B163291D4EE',
  jobRegistryAddress: '0x56d4C7F842B9e825030421F435faFBC4517dbeD6',

  swapAddress : '0xF712977c9c201d012531085F01e168d05be590E5'

};

export default config;
